import * as React from 'react'
import Helmet from 'react-helmet'

/* eslint-disable jsx-a11y/media-has-caption */

const Video = React.forwardRef(
	({ filename, src, poster, preloadPoster, caption, ...props }, ref) => {
		const { width, height, images } = poster.asset.gatsbyImageData
		const posterSrc = images.fallback.src

		const video_url = !!filename ? `/videos/${filename}` : src?.asset.url

		return (
			<>
				<Helmet>
					{preloadPoster && <link rel="preload" href={posterSrc} as="image" />}
					<link rel="preload" href={video_url} as="video" />
				</Helmet>

				<video
					ref={ref}
					poster={posterSrc}
					width={width}
					height={height}
					{...props}
				>
					{video_url && (
						<source src={video_url} type={src?.asset.mimeType || 'video/mp4'} />
					)}
				</video>
			</>
		)
	},
)

export default Video

import * as React from 'react'
import * as css from './ImageAndText.module.css'
import Img from './Img'
import hexLight from 'assets/hex-light.svg'
import clsx from 'clsx'

const Image = ({ image, alt, caption, className, captionStyle, grayscale, ...props }) => (
  <figure className={clsx(className, css.img, 'relative')}>
    <Img className={grayscale && 'grayscale'} image={image} alt={caption || alt || ''} {...props} />

    {!!caption && (
      <figcaption className="location-caption" style={captionStyle}>
        <span className="action">{caption}</span>
      </figcaption>
    )}
  </figure>
)

const Text = ({ className, children }) => (
  <div className={clsx(className, css.txt, '!box-border self-center relative w-full mx-auto px-8 ctnr-half padding-y richtext')}>
    {children}
  </div>
)

const ImageAndText = ({ className, imageOnRight, showHexPattern, children }) => {
  const enableSplit = children?.filter(Boolean).length > 1

  return (
    <section className={clsx(className, 'relative overflow-hidden')}>
      {showHexPattern && (
        <figure className={css.hex}>
          <img
            className="transform -scale-x-100"
            src={hexLight}
            alt=""
            width={767} height={402}
            loading="lazy" draggable="false"
          />
        </figure>
      )}

      <div className={clsx(
        css.wrapper,
        enableSplit && [css.grid, 'grid md:grid-cols-2'],
        imageOnRight && css.imgRight
      )}>
        {children}
      </div>
    </section>
  )
}

export {
  ImageAndText,
  Image,
  Text,
}

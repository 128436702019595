import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Hero from 'components/common/Hero'
import { DescList, DescItem } from 'components/common/DescList'
import TextWithBg from 'components/common/TextWithBg'
import Features from 'components/template-product/Features'

const ProductTemplate = ({ data: { pg } }) => (
  <DefaultLayout {...pg.seo}>
    <Hero {...pg.hero} />
    {!!pg.descList.length && (
      <div className="bg-suede-black text-white">
        <DescList className="ctnr padding-x">
          {pg.descList.map((item, key) => (
            <DescItem title={item.title} key={key}>{item.desc}</DescItem>
          ))}
        </DescList>
      </div>
    )}
    <Features {...pg.features} />
    <TextWithBg {...pg.bottomCTA} />
  </DefaultLayout>
)

export default ProductTemplate

export const query = graphql`query ProductTemplate($id: String) {
  pg: sanityProductsPg(id: {eq: $id}) {
    hero { ...hero }
    descList {
      title
      desc
    }
    features {
      title
      _rawDescription
      link { ...link }
      image { asset { gatsbyImageData(placeholder: NONE) } }
    }
    bottomCTA {
      title
      description
      link { ...link }
      image { asset { gatsbyImageData } }
    }
    seo { title description }
  }
}`

import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'
import CTALink from 'components/common/CTALink'

const Features = ({ title, _rawDescription, link, image }) => (
  <section className="grid md:grid-cols-2 items-center gap-8 ctnr padding">
    {image && <GatsbyImage
      className="multiply"
      image={getImage(image.asset)}
      alt={title}
      objectFit="contain"
    />}

    <div className="max-w-[400px] mx-auto richtext">
      <h2 className="h2">{title}</h2>
      <SanityBlock body={_rawDescription} />
      <CTALink {...link} />
    </div>
  </section>
)

export default Features

import * as React from 'react'
import clsx from 'clsx'

const DescItem = ({ title, children }) => (
  <dl className="max-w-[200px] pl-4 border-l border-line-invert-dark richtext">
    <dt className="font-bold">{title}</dt>
    <dd>{children}</dd>
  </dl>
)

const DescList = ({ className, children }) => (
  <div className={clsx(className, 'grid grid-cols-2 md:grid-cols-4 gap-y-6 gap-x-4 py-16')}>
    {children}
  </div>
)

export {
  DescList,
  DescItem,
}

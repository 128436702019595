import * as React from 'react'
import { ImageAndText, Image, Text } from 'components/common/ImageAndText'
import SanityBlock from 'components/common/SanityBlock'
import CTALink from './CTALink'
import Video from './Video'
import clsx from 'clsx'

export default function Hero({
	pretitle,
	title,
	_rawDescription,
	link,
	image,
	imageOnRight,
	imageColored,
	video,
}) {
	return (
		<ImageAndText className="bg-suede-black text-white" imageOnRight>
			<figure>
				{video ? (
					<Video
						className="aspect-square object-cover"
						muted
						autoPlay
						loop
						playsInline
						preloadPoster
						{...video}
					/>
				) : (
					image && (
						<Image
							className={clsx(imageOnRight && 'md:order-last')}
							image={image?.image}
							draggable={false}
							grayscale={!imageColored}
						/>
					)
				)}
			</figure>

			<Text>
				{pretitle && <p className="technical-lg">{pretitle}</p>}
				{title && <h1 className="h0 !mt-4">{title}</h1>}
				<SanityBlock className="max-w-[400px]" body={_rawDescription} />
				<CTALink {...link} style={{ marginTop: '3em' }} />
			</Text>
		</ImageAndText>
	)
}
